import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '90px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  let searchParams = new URLSearchParams(window.location.search);
  const { signIn, signInWithToken, user, requestToken } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [name, setName] = useState<string>(searchParams.get('n') ?? '');
  const [authError, setAuthError] = useState<Error | null>(null);
  const [room, setRoom] = useState<string>();
  const [smsSend, setSmsSend] = useState<boolean>(false);
  const token = searchParams.get('t');

  const login = () => {
    const matchKey = searchParams.get('key');
    const matchPhone = searchParams.get('num');
    if (!matchKey || !matchPhone) {
      setAuthError(Error('Geen key gevonden. Gebruik de link in de sms.'));
      return;
    }
    setRoom(matchKey);
    setAuthError(null);
    signIn?.(passcode, matchKey, name, matchPhone)
      .then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
      })
      .catch((err) => setAuthError(err));
  };

  useEffect(() => {
    if (token) {
      signInWithToken?.(token, name)
        .then(() => {
          history.replace(location?.state?.from || { pathname: '/' });
        })
        .catch((err) => setAuthError(err));
      return;
    }
    const matchKey = searchParams.get('key');
    const matchPhone = searchParams.get('num');
    if (!matchKey || !matchPhone) {
      setAuthError(Error('Geen key gevonden. Gebruik de link in de sms.'));
      return;
    }
    requestToken(matchKey, matchPhone).then((res) => setSmsSend(res));
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (user) {
    history.replace('/room/' + room);
  }

  return (
    <IntroContainer>
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Voer pincode in (zie sms op nummer {searchParams.get('num')})
        </Typography>
        {!smsSend && (
          <Typography variant="h5" className={classes.gutterBottom}>
            Sms wordt verstuurd
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container justify="space-between">
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-passcode">
                Pincode
              </InputLabel>
              <TextField
                id="input-passcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                type="password"
                variant="outlined"
                size="small"
                disabled={!smsSend}
                autoComplete="one-time-code"
              />
              <div>
                {authError && (
                  <Typography variant="caption" className={classes.errorMessage}>
                    <ErrorOutlineIcon />
                    {authError.message}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          {!name && (
            <Grid container justify="space-between">
              <div className={classes.passcodeContainer}>
                <InputLabel shrink htmlFor="input-name">
                  Name
                </InputLabel>
                <TextField
                  id="input-name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </div>
            </Grid>
          )}
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!passcode.length || !name.length}
              className={classes.submitButton}
            >
              Log in
            </Button>
          </Grid>
        </form>
      </>
    </IntroContainer>
  );
}
