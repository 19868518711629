import React, { useState, useEffect } from 'react';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';

export default function PreJoinScreens() {
  const { getAudioAndVideoTracks, isAcquiringLocalTracks } = useVideoContext();
  const { user } = useAppState();

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    getAudioAndVideoTracks().catch((error) => {
      console.log('Error acquiring local media:');
      console.dir(error);
      setMediaError(error);
    });
  }, [getAudioAndVideoTracks]);

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={SubContent}>
      {!isAcquiringLocalTracks && <DeviceSelectionScreen name={user?.displayName || ''} />}
    </IntroContainer>
  );
}
