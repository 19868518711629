import { useCallback, useState } from 'react';

export function getPasscode() {
  const match = window.location.search.match(/passcode=(.*)&?/);
  const passcode = match ? match[1] : window.sessionStorage.getItem('passcode');
  return passcode;
}

export function fetchToken(name: string, room: string, passcode: string, phoneNumber: string) {
  return fetch(process.env.REACT_APP_TOKEN_ENDPOINT || `https://ivr.gericall.nl/token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ name, room, passcode, phonenumber: phoneNumber }),
  });
}

export async function reqToken(room: string, phoneNumber: string) {
  try {
    const res = await fetch(process.env.REACT_APP_REQUEST_TOKEN_ENDPOINT || `https://ivr.gericall.nl/requesttoken`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ room, phonenumber: phoneNumber }),
    });
    if (res.status === 401) {
      return false;
    }
    if (res.ok) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}

export async function verifyPasscode(passcode: string, room: string, name: string, phoneNumber: string) {
  const res = await fetchToken(name, room, passcode, phoneNumber);
  const jsonResponse = await res.json();
  if (res.status === 401) {
    return { isValid: false, error: jsonResponse.error?.message };
  }
  if (res.ok && jsonResponse.token) {
    return { isValid: true, token: jsonResponse.token };
  }
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const [user, setUser] = useState<{ displayName: undefined; photoURL: undefined; passcode: string } | null>(null);
  const [token, setToken] = useState<string>();

  const getToken = useCallback(() => {
    return token;
  }, [token]);

  const signIn = useCallback(async (passcode: string, room: string, name: string, phoneNumber: string) => {
    const verification = await verifyPasscode(passcode, room, name, phoneNumber);
    if (verification?.isValid) {
      setUser({ passcode, displayName: name } as any);
      window.sessionStorage.setItem('passcode', passcode);
      setToken(verification.token);
    } else {
      throw new Error(getErrorMessage(verification?.error));
    }
  }, []);

  const signInWithToken = useCallback(async( token:string, name: string)=>{
    setUser({ token, displayName: name } as any);
    setToken(token);
  },[]);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.removeItem('passcode');
    return Promise.resolve();
  }, []);

  const requestToken = useCallback((room: string, phonenumber: string) => reqToken(room, phonenumber), []);

  return { user, isAuthReady: false, getToken, signIn, signInWithToken ,signOut, roomType: 'peer-to-peer', requestToken };
}
